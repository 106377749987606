// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"Rubik", sans-serif'
  }
});

export default theme;
// Customizable Area End