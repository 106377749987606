// @ts-nocheck
// Customizable Area Start
import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import withSnackBar from "../HOC/withSnackBar.Web";

// import { upload } from "./assets";
export type UploadBoxProps = {
  onSelectFile: (Files: FileList) => {};
  onTouched?: () => {};
  multiple: boolean;
  classes: Record<string, string>;
  uploadMsg: String | React.ReactNode;
  acceptedFormat: string;
  uploadimg?: string;
  sizeMsg?: string;
  uploadIcon?: String | React.ReactNode;
  height?: string;
  minHeight?: string;
  width?: string;
  dropContainerStyle: React.CSSProperties;
  editMode?: boolean;
  messageMargin?: string;
  name?: string;
  uploadText?: string;
  uploadIconStyles?: React.CSSProperties;
  imgRef?: React.RefObject<HTMLImageElement>;
};
class UploadBox extends React.Component<UploadBoxProps> {
  state = { inputDisabled: false };
  fileInputRef = React.createRef<HTMLInputElement>();
  dragOver(e: { preventDefault: () => void }) {
    e.preventDefault();
  }

  dragEnter(e: { preventDefault: () => void }) {
    e.preventDefault();
  }

  dragLeave(e: { preventDefault: () => void }) {
    e.preventDefault();
  }

  fileDrop(e: { preventDefault: () => void; dataTransfer: { files: any } }) {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.handleFiles(files);
  }

  fileInputClicked() {
    this.props.onTouched?.();
    this.fileInputRef?.current?.click();
  }

  filesSelected(e) {
    this.setState({ inputDisabled: true });
    const files = e.target?.files || e.dataTransfer?.files || [];
    const acceptedFormats = (this.props.acceptedFormat || "image/*")
        .replace("*", "")
        .split(", ");

    if (files.length) {
      for (const file of files) {
        if (
            !acceptedFormats.some(
                (i) =>
                    file.type.startsWith(i) || file.name.endsWith(i)
            )
        ) {
          this.activateInput();
          return this.props.showToast({
            message: `Type error on file "${files[i].name}"`,
            type: "error",
          });
        }
        const fsize = file.size;
        const fileSizeMb = fsize / 1024 / 1024;
        // The size of the file.
        if (fileSizeMb > 20) {
          this.props.showToast({
            message: "File too Big, please select a file less than 20mb",
            type: "error",
          });
          this.activateInput();
          return false;
        }
      }

      this.handleFiles(files);
    }
    this.activateInput();
  }

  activateInput = () => {
    // used for reset input
    // image input is not controlled, if we reset user can select same image again and again
    // if we dont reset and user clear selected image, cannot add same image again
    setTimeout(() => {
      this.setState({ inputDisabled: false });
    }, 250);
  };

  handleFiles(files: FileList) {
    this.props.onSelectFile(files);
  }

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive: false });
    }
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      this.filesSelected(e);
    }
  };

  render() {
    const { classes, dropContainerStyle } = this.props;
    return (
        <div
        onDragEnter={this.handleDrag}
        onDragOver={this.handleDrag}
        className={`${classes.DropperWrapper} extraClass`}
      >
        {this.props.editMode ? (
          <div
           className={classes.edit}
            onClick={this.fileInputClicked.bind(this)}
          >
            Edit
          </div>
        ) : (
          <>
            <div
              style={dropContainerStyle}
              className={`${classes.DropContainer} ExtraClassDropContainer`}
              onClick={this.fileInputClicked.bind(this)}
            >
              {this.props.uploadText && <span>{this.props.uploadText}</span>}
              <div className={`${classes.dropMessage} ExtraClassdropMessage`}>
                <img src={this.props.uploadIcon} style={this.props.uploadIconStyles} />
                {this.props.uploadMsg && (
                  <div className={`${classes.dropMessage} ExtraClassdropMessage`} style={{fontSize:'20px', fontWeight:500, fontFamily:'Rubik'}}>
                    {" "}
                    {this.props.uploadMsg}
                  </div>
                )}
                {this.props.sizeMsg && (
                  <div className={`${classes.sizeMsg} ExtraClasssizeMsg`}> {this.props.sizeMsg}</div>
                )}
              </div>

            </div>

          </>

        )}

        {!this.state.inputDisabled && (
          <input
            data-testid={"UploadBanner"}
            name={this.props.name}
            ref={this.props.imgRef || this.fileInputRef}
            className={classes.fileInput}
            type="file"
            accept={this.props.acceptedFormat || "image/*"}
            multiple={this.props.multiple || false}
            onChange={this.filesSelected.bind(this)}
          />

        )}

        {this.state.dragActive && (
          <div
            className={classes.DropperActive}
            onDragEnter={this.handleDrag}
            onDragLeave={this.handleDrag}
            onDragOver={this.handleDrag}
            onDrop={this.handleDrop}
          />
        )}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
    createStyles({
      DropperWrapper: {
        position: "relative",
      },
      DropperActive: {
        position: "absolute",
        top: -20,
        right: -20,
        bottom: -20,
        left: -20,
      },
      DropContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: (props) => (props.margin ? props.margin : "16px 20px"),
        width: (props) => (props.width ? props.width : "50%"),
        height: (props) => props.height,
        minHeight: (props) => props.minHeight,
        border: "2px dashed #C0C3CE",
        flexDirection: "column",
        borderRadius: "10px",
        //padding: "20px 10px",
        "&:hover": {
          cursor: "pointer",
        },
      },
      dropMessage: {
        fontFamily: "Rubik",
        fontSize: "18px",
        fontWeight: 400,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#676B7E",
        margin: (props) => props.messageMargin || "8px auto",
      },
      sizeMsg: {
        color: "#AFB2C0",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight:'25px !important'
      },
      marginTop: {
        marginTop: theme.spacing(2),
      },
      fileInput: {
        display: "none",
      },
      edit: {
        fontSize: "10px",
        cursor: "pointer",
        fontWeight: 400,
        textDecoration: "underline",
        color: "#6200EA",
      },
      sizeMsg: {
        color: "#AFB2C0",
        fontSize: "10px",
        fontWeight: 400,  
      }, 
      marginTop: {
        marginTop: theme.spacing(2),
      },
  
      fileInput: {
        display: "none",
      },
  
      edit: {
        fontSize: "10px",
        cursor: "pointer",
        fontWeight: 400,
        textDecoration: "underline",
        color: "#6200EA",
      },
    });
export default withStyles(styles, { withTheme: true })(withSnackBar(UploadBox));
// Customizable Area End
