// Customizable Area Start
import { Availability } from "../models/Availability";
import moment from "moment";
import { ServiceImage } from "../models/ServiceImage";
import { Banner } from "../models/Banner";
import { AppHeaderData } from "../../../header/src/AppheaderController.web";
import { BrandsResponseData } from "../../../dashboard/src/DashboardController.web";
import { Footer } from "../../../footer/src/AppfooterController.web";
import { isJson } from "../../../AdminWebsiteBuilder/src/helpers";

export const parseCategory = (response: any, isCatalogue: boolean) => {
  if (!isCatalogue) {
    return {
      id: response?.attributes?.id || 0,
      name: response?.attributes?.name || "",
      count: response?.attributes?.count || 0,
      image: response?.attributes?.image_url || "",
      favicon: response?.attributes?.image_url || "",
    };
  }

  return {
    id: response?.id || 0,
    name: response?.name || "",
    count: response?.count || 0,
    image: response?.image_url || "",
    favicon: response?.image_url || "",
  };
};

export const formatAvailability = (availability: any[]): Availability[] => {
  return availability?.map((data) => {
    const day = data?.working_day;
    const workingHours = data?.working_hours?.map(
      (working_hour: { start_time: string; end_time: string }) => {
        return {
          openingTime: working_hour?.start_time,
          closingTime: working_hour?.end_time,
        };
      }
    );
    const selected = data?.is_available;

    return {
      day,
      workingHours,
      selected,
    };
  });
};

export const parseCatalogue = (response: any) => {
  const {
    category: responseCategory,
    ...responseService
  } = response.attributes;

  const category = parseCategory(responseCategory, true);

  return {
    id: response?.id || 0,
    title: responseService?.title || "",
    description: responseService?.description || "",
    images: responseService?.images || [],
    thumbnail_url: responseService?.thumbnail_url || [],
    small_url: responseService?.small_url || [],
    duration: responseService?.duration || 0,
    undiscountedPrice: responseService?.actual_price || 0,
    slug: responseService?.slug || "",
    discountedPrice: responseService?.price || 0,
    hasDiscount: responseService?.discount_option || false,
    discount: responseService?.discount || "",
    paymentPreference: responseService?.payment_preferences || "",
    status: responseService?.status || false,
    category,
    availability: formatAvailability(responseService?.availability) || [],
    enable_booking_per_slot: responseService?.enable_booking_per_slot || false,
    number_of_booking_per_slot: responseService?.number_of_booking_per_slot || ""
  };
};

export const formatShopAvailability = (availability: any[]) => {
    return availability?.map(
    (opening_hour: {
      week_day: string;
      start_time: string;
      end_time: string;
      selected:boolean;
    }) => {
      return {
        weekDay: opening_hour?.week_day.slice(0, 3),
        openingTime:opening_hour?.start_time == "" || opening_hour?.start_time == undefined || !opening_hour?.selected ? "" : moment(opening_hour?.start_time).utc().format("hh:mm A"),
        closingTime: opening_hour?.end_time == "" || opening_hour?.end_time == undefined || !opening_hour?.selected ? "" : moment(opening_hour?.end_time).utc().format("hh:mm A"),
      };
    }
  );
};

export const parseLocation = (response: any) => {
  response.attributes.opening_hours.map((item: {end_time: string,id: number,selected: boolean,start_time: string,week_day: string}) => {
    item.selected = true
  })
  return {
    location:
      {
        mapLink: response?.attributes?.location_url,
        image: response?.attributes?.image,
        favicon: response?.attributes?.favicon,
      } || "",
    city: response?.attributes?.city || "",
    state: response?.attributes?.state || "",
    country: response?.attributes?.country || "",
    postalCode: response?.attributes?.pin_code || "",
    addressLine1: response?.attributes?.address_line1 || "",
    addressLine2: response?.attributes?.address_line2 || "",
    shopAvailability:
      formatShopAvailability(response?.attributes?.opening_hours) || {},
      online_booking: response?.attributes?.online_booking || false,
      offline_alert_text: response?.attributes?.offline_alert_text || "",
      same_offline_time_for_all: response?.attributes?.same_offline_time_for_all || false,
      offline_days: response?.attributes?.offline_days || [],
      store_closing_days: response?.attributes?.store_closing_days || "",
      show_message: response?.attributes?.show_message || false
  };
};

const formatLogo = (header: any): ServiceImage => {
  return {
    id: header?.id,
    url: header?.image?.url || "",
    small_url:  header?.image?.small_url || "", 
    thumbnail_url:  header?.image?.thumbnail_url || "",
    webp_url: header?.image?.webp_url || ""
  };
};
const formatfavicon = (header: any): ServiceImage => {
  return {
    id: header?.id,
    url:  header?.favicon?.url || "",
    small_url:  header?.favicon?.small_url || "", 
    thumbnail_url:  header?.favicon?.thumbnail_url || "",
    webp_url: header?.favicon?.webp_url || ""
  };
};

const formatBanners = (banners: any[]): Banner[] => {
  return banners?.map((data) => {
    const id = data?.id;
    const url = data?.redirect_url || "";
    const image = { id: data?.id, url: data?.image?.url, small_url: data?.small_url?.url, thumbnail_url: data?.image?.thumbnail_url, webp_url: data?.image?.webp_url };
    const favicon = { id: data?.id, url: data?.favicon?.url, small_url: data?.favicon?.small_url, thumbnail_url: data?.favicon?.thumbnail_url, webp_url: data?.favicon?.webp_url };

    return {
      id,
      url,
      image,
      favicon,
    };
  });
};

export const parseBrand = (response: any) => {
    return {
    id: response?.attributes?.id || 0,
    logo: formatLogo(response?.attributes?.header) || {},
    favicon: formatfavicon(response?.attributes?.header) || {},
    storeName: response?.attributes?.header?.store_name || "",
    countryCode: response?.attributes?.footer?.country_code || "",
    phoneNumber: response?.attributes?.footer?.phone_number || "",
    copyright: response?.attributes?.footer?.copy_right_text || "",
    socialMedias: response?.attributes?.footer?.social_media || {},
    applications: response?.attributes?.footer?.download_apps || {},
    banners: formatBanners(response?.attributes?.banners) || [],
    testimonial: response?.attributes?.testimonial || false,
    renameItem1: response?.attributes?.header?.navigation_item_1 || "",
    renameItem2: response?.attributes?.header?.navigation_item_2 || "",
    renameItem3: response?.attributes?.header?.navigation_item_3 || "",
    testimonialName: response?.attributes?.top_service_section_name || "",
    websiteTitle: response?.attributes?.header?.website_title || ""
  };
};

export const parseBanner = (response: BrandsResponseData) => {
  return {
    testimonial: response?.attributes?.testimonial || false,
    testimonialName: response?.attributes?.top_service_section_name || "",
    banners: formatBanners(response?.attributes?.banners) || [],
  }
}

export const parseHeader = (response: AppHeaderData, storename: string) => {
  const ParseData = isJson(response?.attributes?.header?.data?.attributes?.metadata) ? JSON.parse(response?.attributes?.header?.data?.attributes?.metadata) : response?.attributes?.header?.data?.attributes?.metadata;
    return {
    logo: response?.attributes?.header?.data?.attributes?.logo_url || "",
    renameItem1: ParseData?.nevigationItem1 || "",
    renameItem2: ParseData?.nevigationItem2 || "",
    renameItem3: ParseData?.nevigationItem3 || "",
    show_business_name: response?.attributes?.header?.data?.attributes?.show_business_name || false,
    favicon: response?.attributes?.header?.data?.attributes?.favicon_url || storename.charAt(0),
    color: response?.attributes?.header?.data?.attributes?.logo_colour || "",
    font: response?.attributes?.header?.data?.attributes?.logo_font || "",
  }
}
export const parseFooter = (response: Footer) => {
  return {
    countryCode: response?.data?.attributes?.footer?.country_code || "",
    phoneNumber: response?.data?.attributes?.footer?.phone_number || "",
    copyright: response?.data?.attributes?.footer?.copy_right_text || "",
    socialMedias: response?.data?.attributes?.footer?.social_media || {},
    applications: response?.data?.attributes?.footer?.download_apps || {}
  };
};
// Customizable Area End