import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import StorageProvider from "../../../framework/src/StorageProvider.web";

export type HeaderData = {
    favicon_url: string;
    custom_favicon: boolean;
    logo_font: string;
    logo_colour: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    storeName: string;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class WebsiteBuilderThemeController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getWebsiteThemeSettingApiCallId: string;
    getStoreDetailsCallId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            storeName: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.getWebsiteThemeSettingApiCallId = "";
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiMessageType = getName(MessageEnum.RestAPIResponceMessage);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (message.id === apiMessageType) {
            if (!responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.getWebsiteThemeSettingApiCallId) {
                    this.setThemeColors(responseJson.data.attributes.themes_and_font.data.attributes);
                    this.setFavicon(responseJson.data.attributes.header.data.attributes, this.state.storeName)
                    StorageProvider.set("navigationData", responseJson.data.attributes.header.data.attributes.metadata);
                    
                    if (localStorage.getStorageData("theme_setting_data") === JSON.stringify({font_and_colors:responseJson.data.attributes.themes_and_font.data.attributes, header_data:responseJson.data.attributes.header.data.attributes, store_name:responseJson?.meta?.store_name.trim()})) {
                        await setStorageData("theme_setting_data", JSON.stringify({font_and_colors:responseJson.data.attributes.themes_and_font.data.attributes, header_data:responseJson.data.attributes.header.data.attributes, store_name:responseJson?.meta?.store_name.trim()}))
                      } else {
                        await setStorageData("theme_setting_data", JSON.stringify({font_and_colors:responseJson.data.attributes.themes_and_font.data.attributes, header_data:responseJson.data.attributes.header.data.attributes, store_name:responseJson?.meta?.store_name.trim()}))
                        window.location.reload();
                      }
                }
            } else {
                this.parseApiErrorResponse(responseJson.errors && responseJson);
            }

            if (!responseJson.errors && !responseJson.error) {
                if (apiRequestCallId === this.getStoreDetailsCallId) {
                   this.setState({storeName: responseJson?.data?.attributes?.name})
                }
            } else {
                this.parseApiErrorResponse(responseJson.errors && responseJson);
            }
        }
        this.parseApiCatchErrorResponse(errorReponse);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getWebsiteThemeSetting();
        this.getStoreDetails()
        const cachedSetting = await getStorageData("theme_setting_data", true);
        if(cachedSetting){
            this.setThemeColors(cachedSetting.font_and_colors);
            this.setFavicon(cachedSetting?.header_data, this.state.storeName)
        }
    }
    setFavicon = (headerData:HeaderData, storeName:string = "S") => {
        const text = storeName;
        
        const textColor = headerData.logo_colour;
        const faviconElement = document.querySelector("[rel='shortcut icon']") as HTMLAnchorElement;
        
        let imgElement = document.createElement("img"); 
        imgElement.src = faviconElement.href;
      
        let canvas = document.createElement( "canvas" );
        let context = canvas.getContext("2d");
        canvas.width = 128;
        canvas.height = 128;
      
        imgElement.onload = () => {this.applyFavicon(canvas, context, imgElement, faviconElement, text, {logo_font:headerData.logo_font, textColor, custom_favicon:headerData.custom_favicon, favicon_url:headerData.favicon_url})}
    }

    applyFavicon = ( canvas:HTMLCanvasElement, context:CanvasRenderingContext2D | null, imgElement: HTMLImageElement, faviconElement:HTMLAnchorElement, text:string,headerData: {logo_font:string; textColor:string; custom_favicon:boolean; favicon_url:string;}) => {
        const bgColor = "#ffffff";
        if(context){
          // Draw Original Favicon as Background
          context.drawImage( imgElement, 0, 0, 128, 128 );
      
          // Draw Notification Circle
          context.beginPath();
          context.rect( 0, 0, canvas.width, canvas.height );
  
          context.fillStyle = bgColor;
          context.fill();
      
          // Draw Notification Number
          context.font = `bold 110px "${headerData.logo_font}", sans-serif`;
          context.textAlign = "center";
          context.textBaseline = "middle";
          context.fillStyle = headerData.textColor;
      
          let letter = text.substring( 0, 1 );
          context.fillText( letter, canvas.width / 2, 76 );
          // Replace favicon
          faviconElement.href = headerData.custom_favicon ? headerData.favicon_url : canvas.toDataURL( "image/png" );
        }
      };

    setThemeColors = (setting: { body_text: string | null; header_text: string | null; primary_colour: string | null; secondary_colour: string | null }) => {
        const primaryColour: string = setting.primary_colour ?? "#512888";
        const primaryDarkAccentColour: string = this.generateAccentColor(primaryColour, 0.40) ?? "rgba(136, 51, 255, 0.4)";
        const primaryLightAccentColour: string = this.generateAccentColor(primaryColour, 0.13) ?? "rgba(136, 51, 255, 0.13)";
        const secondaryColour: string = setting.secondary_colour ? setting.secondary_colour : "#222222";
        const secondaryDarkAccentColour: string = this.generateAccentColor(secondaryColour, 0.80) ?? "rgba(34, 34, 34, 0.8)";
        const secondaryLightAccentColour: string = this.generateAccentColor(secondaryColour, 0.70) ?? "rgba(34, 34, 34, 0.7)";
        this.changeCSSVariable("--website-builder-primary-color", primaryColour);
        this.changeCSSVariable("--website-builder-primary-dark-accent-color", primaryDarkAccentColour)
        this.changeCSSVariable("--website-builder-primary-light-accent-color", primaryLightAccentColour)
        this.changeCSSVariable("--website-builder-secondary-color", secondaryColour)
        this.changeCSSVariable("--website-builder-secondary-dark-accent-color", secondaryDarkAccentColour)
        this.changeCSSVariable("--website-builder-secondary-light-accent-color", secondaryLightAccentColour)
        this.changeCSSVariable("--website-builder-header-font", setting.header_text ? setting.header_text : 'Lato, sans-serif, "sans-serif"')
        this.changeCSSVariable("--website-builder-body-font", setting.body_text ? setting.body_text : 'Lato, sans-serif, "sans-serif"')
    }
    getWebsiteThemeSetting = () => {
        const header = {
            "Content-Type": configJSON.contentTypeApplicationJson,
        };
        const getThemeMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getWebsiteThemeSettingApiCallId = getThemeMessage.messageId;
        getThemeMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getContentWebsiteBuilderEndPoint);
        getThemeMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        getThemeMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
        runEngine.sendMessage(getThemeMessage.id, getThemeMessage);
    }
    generateAccentColor = (colour: string, opacityPercentage:number) => {
        let redColor, greenColor, blueColor, transparentColor;

        // RGBA format
        if (colour.startsWith("rgba(")) {
            const rgbaValues = colour.slice(5, -1).split(",");
            redColor = parseInt(rgbaValues[0]);
            greenColor = parseInt(rgbaValues[1]);
            blueColor = parseInt(rgbaValues[2]);
            transparentColor = parseFloat(rgbaValues[3]);
        }
        // HEX format
        else if (colour.startsWith("#") && (colour.length === 4 || colour.length === 7)) {
            redColor = parseInt(colour.slice(1, 3), 16);
            greenColor = parseInt(colour.slice(3, 5), 16);
            blueColor = parseInt(colour.slice(5, 7), 16);
            transparentColor = 1;
        }
        // RGB format
        else if (colour.startsWith("rgb(")) {
            const rgbValues = colour.slice(4, -1).split(",");
            redColor = parseInt(rgbValues[0]);
            greenColor = parseInt(rgbValues[1]);
            blueColor = parseInt(rgbValues[2]);
            transparentColor = 1;
        }
        else {
            return null;
        }



        return `rgba(${redColor}, ${greenColor}, ${blueColor}, ${transparentColor * opacityPercentage})`;
    }
    changeCSSVariable(variableName: string, value: string) {
        document.documentElement.style.setProperty(variableName, value);
    }

    getStoreDetails = () => {
        const header = {
          "Content-Type": configJSON.contentTypeApplicationJson
        };
    
        this.getStoreDetailsCallId = BlockHelpers.callApi({
          method: configJSON.getMethod,
          endPoint: configJSON.getStoreDetails,
          header
        });
    };
    // Customizable Area End
}
