import React from "react";
// Customizable Area Start
import AppfooterController, { Props } from "./AppfooterController.web";
import Appfooter from "./Appfooter.web";
// Customizable Area End

export default class AppfooterWrapper extends AppfooterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Appfooter
        phoneNumber={this.state.phoneNumber}
        socialMedias={this.state.socialMedias}
        applications={this.state.applications}
        copyright={this.state.copyright}
        staticPages={this.state.staticPages}
        brandFooterFacebookUrl={this.state.brandFooterFacebookUrl}
        brandFooterInstagramUrl={this.state.brandFooterInstagramUrl}
        brandFooterXurl={this.state.brandFooterXurl}
        brandFooterYoutubeurl={this.state.brandFooterYoutubeurl}
        brandFooteAppStoreUrl={this.state.brandFooteAppStoreUrl}
        brandFootePlayStoreUrl={this.state.brandFootePlayStoreUrl}
        brandFootertogle={this.state.brandFootertogle}
        brandFooterContact={this.state.brandFooterContact}
        brandFooterApplink={this.state.brandFooterApplink}
        copyRight={this.state.copyRight}
        loading={this.state.loading}
        buildCardID={""}
        footerData={this.state.footerData}
      />
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
