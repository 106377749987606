// @ts-nocheck
import { BlockComponent } from '../../../../framework/src/BlockComponent'
import { RouterProps } from "react-router";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../Components/withBrandingSpinner.web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";

export type Props = RouterProps &
  DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps & {
    id: string;
    // Customizable Area Start
    isAspectRatio: boolean;
    isBanner?: boolean; 
    onCancelProp?: () => void;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddCropperController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
      super(props);
      this.state = {
          zoom: 0
      }
  }
  zoomIn = () => {
      if (this?.cropper?.cropper?.zoom !== "undefined") {
          this.setState(prevState => {
              return {
                  ...prevState,
                  zoom: prevState.zoom + 0.1
              }
          }, () => {
              this?.cropper?.cropper?.zoom(0.1)
          })
      }
  }
  zoomOut = () => {
      if (this?.cropper?.cropper?.zoom !== "undefined") {
          this.setState(prevState => {
              return {
                  ...prevState,
                  zoom: prevState.zoom - 0.1
              }
          }, () => {
              this?.cropper?.cropper?.zoom(-0.1)
          })
      }
  }
  handleZoomChange(event: any, newValue: number | number[]) {
      this.setState({
          zoom: +newValue
      }, () => {
          this.cropper?.cropper?.zoomTo((+newValue));
      })
  };
}