Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.contentTypeApplicationJson = "application/json";
exports.getMethod = "GET";
exports.postMethod = "POST";
exports.getBrandsEndPoint = "bx_block_catalogue/brands/home_brand_banner";
exports.getSectionsEndPoint= "bx_block_website_builder/designs/get_design";
exports.getTopServicesEndPoint = "bx_block_catalogue/catalogues/top_services";
exports.getLocationEndPoint = "bx_block_settings/common_settings";
exports.getCustomerFeedbackEndPoint = "bx_block_customer_feedback/customer_feedbacks";
exports.textTESTIMONIALS = "TESTIMONIALS";
exports.landingPageEnter = "webcustomer_landing_page_enter";
exports.noBannerText = "No Banner added";
exports.getContentWebsiteBuilderEndPoint = "bx_block_website_builder/designs/get_design";
exports.textBookNow="Book Now";
exports.meetOurStylistsText = "Meet our talented stylists";
exports.viewAllText = "View all";
exports.address = "Shop address";
exports.openingHours = "Opening time";
exports.servicesSelectItem = "_webcustomer_services_select_item";
exports.getStoreDetails = "bx_block_store_details/public/store_profile";
exports.weAreClosed = "We are currently closed for online bookings";
exports.getFormAPIEndpoint = "bx_block_storecontentmanagement2/marketings/open_api";
exports.PostFormAPIEndpoint = "bx_block_storecontentmanagement2/marketings/form_create";
exports.SubmitLable = "Submit";
exports.thanksLable = "Thank You!";
exports.submissionLable = "Your submission has been received.";
// Customizable Area End
