// Customizable Area Start
Object.defineProperty(exports, '__esModule', {
  value: true
});

exports.cancel = "Upload New";
exports.cropDesktop = "Crop & Save";
exports.cropMobile = "Crop & Mobile";
exports.crop = "Save";
exports.edit = "Edit";
// Customizable Area End