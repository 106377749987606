// Customizable Area Start
import React from "react";
import BrandingController,{ SectionDataInterfaceType } from "./BrandingController.web";
import MuiTabs from "@material-ui/core/Tabs";
import {
  Box,
  styled,
} from "@material-ui/core";
import MuiTab from "@material-ui/core/Tab";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import "./styles.css";
import { Scrollbars } from "react-custom-scrollbars";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import { DesktopIcon, MobileIcon } from "./assets";
import { Header } from "./HeaderBranding.web";
import ProductPreviewScreen from "./ProductPreviewScreen.web";
import ProductPreviewDesktopScreenWeb from "./ProductPreviewDesktopScreen.web";
import ContentInformation from "./ContactInformation.web";

export const configJSON = require("./config.js");


class Branding extends BrandingController {

  render() {
    const {
      selectedTab,
    } = this.state;

    return (
      <Scrollbars ref={this.scrollRef}>
        <div className="branding-main">
          <Header>
            <div className="tabs-wrapper">
              <Tabs onTabChange={this.handleTabChange} value={selectedTab === "mobile" ? 0 : 1} data-test-id={"TabChangeTestId"} />
            </div>
          </Header>
        </div>
        <MobileTab>
          {this.state.selectedTab === "mobile" &&
            <ProductPreviewScreen
              updateExpandedSection={this.updateExpandedSection}
              selectedSection={this.state.selectedSection}
              createId={this.state.createId}
              categoryData={this.state.categoryData}
              productData={this.state.productData}
              updateData={this.state.updateData}
              isApiCalled={this.state.isApiCalled}
              statesData={this.state}
              sectionCategoryTitle={this.state.sectionCategoryTitle}
            />
          }
          {
            this.state.selectedTab === "desktop" &&
            <ProductPreviewDesktopScreenWeb
              updateExpandedSection={this.updateExpandedSection}
              selectedSection={this.state.selectedSection}
              createId={this.state.createId}
              categoryData={this.state.categoryData}
              productData={this.state.productData}
              updateData={this.state.updateData}
              isApiCalled={this.state.isApiCalled}
              statesData={this.state}
              sectionCategoryTitle={this.state.sectionCategoryTitle}
            />
          }
          <ContentInformation
            selectedTab={selectedTab}
            createIdHandler={this.creatIdHandler}
            apiCalledHandler={this.apiCalledHandler}
            data-test-id={"CreateHandlerTestId"}
            realTimeHandler={this.realTimeHandler}
            realTimeSectionHandler={this.realTimeSectionHandler}
            realTimeSectionCategoryName={this.realTimeSectionCategoryName}
            realTimeCategoryHandler={this.realTimeCategoryHandler}
            realTimeProductHandler={this.realTimeProductHandler}
            HandleStoreNameEnabled={this.HandleStoreNameEnabled}
            handleTestimonialNameEnabled = { this.handleTestimonialNameEnabled}
            handleLocationNameEnabled = {this.handleLocationNameEnabled}
            HandleLogoUpload={this.HandleLogoUpload}
            HandleMobileMenyTrigger={this.HandleMobileMenyTrigger}
            HandleCanvasDataGetAPIResponse={this.HandleCanvasDataGetAPIResponse}
            HandleNavigationMenuOptions={this.HandleNavigationMenuOptions}
            handleSectionAdd={this.handleSectionAdd}
            updateSectionName={this.updateSectionName}
            deleteSection={this.deleteSection}
            updateBannerMobileData={this.updateBannerMobileData}
            updateBannerUrl={this.updateBannerUrl}
            updateBannerMobileMetadata={this.updateBannerMobileMetadata}
            updateBannerDesktopMetadata={this.updateBannerDesktopMetadata}
            updateSectionType={this.updateSectionType}
            parentStates={this.state}
            handleSectionDropDownOpen={this.handleSectionDropDownOpen}
            handleGetAPITrigger={this.handleGetAPITrigger}
            handleSocialLinkToggle={this.handleSocialLinkToggle}
            overrideCategoryOrProductSelectedValue={this.overrideCategoryOrProductSelectedValue}
            selectedSection={this.state.selectedSection}
            expandedSection={this.state.expandedSection}
            updateSelectedSection={this.updateSelectedSection}
            updateExpandedSection={this.updateExpandedSection}
            updateFooter={this.updateFooter}
            handleCloseAllSections={this.handleCloseAllSections}
            handleTextLogoFontChange={this.handleTextLogoFontChange}
            handleTextLogoColorChange={this.handleTextLogoColorChange}
            HandleTextLogoData={this.HandleTextLogoData}
            storeName={this.state.storeName}
            handleBannerBackgroundColor={this.handleBannerBackgroundColor}
            handleBannerPosition={this.handleBannerPosition}
            updatedArray={(updatedArray: SectionDataInterfaceType[]) => this.handleDragAndDrop(updatedArray)}
            updateNavigationName={this.updateNavigationName}
            handleColors={this.handleColors}
            handleText={this.handleFonts}
            resetSection={this.resetSection}
            handleColorsSelection={this.handleColorsSelection}
          />
        </MobileTab>
      </Scrollbars>
    );
  }
}

interface TabProps {
  onTabChange?: (event: React.ChangeEvent<{}>, value: number) => void;
  value: number;
}

export const Tabs = ({ onTabChange, value }: TabProps) => {
  return (
    <MuiTabs
      classes={{
        root: "tabs-root",
        indicator: "tabs-indicator",
        flexContainer: "tabs-flex-container",
      }}
      value={value}
      onChange={onTabChange}
    >
      {[
        [configJSON.textNewMobile, MobileIcon],
        [configJSON.textNewDesktop, DesktopIcon],
      ].map(([label, Icon], itemIndex) => (
        <MuiTab
          data-testid={`preview-device-${label}`}
          classes={{
            root: "tabs-root",
            labelIcon: "tab-label-icon",
            selected: "tab-label-selected",
            wrapper: "tab-icon-wrapper",
          }}
          label={label}
          icon={
            <Icon
              className="tab-icon"
              color={value === itemIndex ? "#6200EA" : "#9B9FB1"}
            />
          }
        />
      ))}
    </MuiTabs>
  );
};

const MobileTab = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});


export default withLayout(withDialog(withLoader(withHeaderBar(Branding))));
export { Branding }
// Customizable Area End