import React from "react";


// Customizable Area Start

import {
  Box,
  Button,
  Typography,
  Grid,
  Link
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { adminUser, builderUser } from "./assets"
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";


const theme = createTheme({
  typography: {
      fontFamily: 'Rubik',
  }
});
// Customizable Area End

import Servicespecificsettingsadmin2Controller, {
  Props,
  configJSON,
} from "./Servicespecificsettingsadmin2Controller";

export class Servicespecificsettingsadmin2 extends Servicespecificsettingsadmin2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={12} style={webStyle.mainContainer}>
            <Typography data-testid="welcome-message" style={webStyle.welcomemessage}>{configJSON.welcomeToAccount}{this.state.isSuperAdmin ? configJSON.adminUser : configJSON.builderUser }</Typography>
           
            <Box style={webStyle.boxMainContainer}>
              {this.state.userDetails.map(data => 
                <Box style={webStyle.adminBox} key={data?.attributes?.id}>
                  {data?.attributes?.super_admin ?
                  <>
                    <Box style={webStyle.builderIcon}>
                        <img src={adminUser} alt="builder user"/>
                    </Box>
                    <Typography style={webStyle.profileText}>{configJSON.profile}</Typography>
                    <Typography style={webStyle.adimUserText}>{configJSON.adminUser}</Typography>
                    <Typography style={webStyle.adminUserMail}>{data?.attributes?.email}</Typography>
                  </> :
                  <>
                    <Box style={webStyle.builderIcon}>
                        <img src={builderUser} alt="builder user"/>
                    </Box>
                    <Typography style={webStyle.profileText}>{configJSON.builderUser}</Typography>
                    <Typography style={webStyle.adimUserText}>{configJSON.accountDetails}</Typography>
                  </>
                  }
                  {data?.attributes?.email === this.state.adminEmail &&
                    <Button style={webStyle.editButton} onClick={this.handleEditProfile} data-testid="edit-button">
                      {configJSON.editButton}
                    </Button>
                  }
                </Box> 
              )
              }
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  editButton:{
   border: '2px solid #364f6b',
   borderRadius: '5px',
   color: '#364f6b',
   padding: '12px 50px',
   height: 'fit-content',
   background: '#ffffff',
   whiteSpace: 'nowrap' as 'nowrap',
   textTransform: 'none' as 'none',
   marginTop: '20px',
   width:'100%'
  },
  welcomemessage:{
   textAlign: 'center' as 'center',
   fontSize: '1.75rem',
   fontWeight: 500,
   color: '#3C3E49'
  },
  mainContainer:{
   padding:'50px 20px'
  },
  linkBox:{
   textAlign: 'center' as 'center'
  },
  linkText:{
   fontFamily:'Rubik',
   fontSize:'1rem',
   fontWeight:400,
   color:'#FF1744',
   cursor: 'pointer'
  },
  boxMainContainer:{
   display:'flex',
   flexWrap:'wrap' as 'wrap',
   gap:'20px',
   paddingTop:'50px'
  },
  adminBox:{
   border: '2px solid #ECEDF0',
   padding:'15px',
   width:'270px'
  },
  builderBox:{
   border: '2px solid #ECEDF0',
   padding:'15px',
   width:'270px',
  },
  profileText:{
   fontSize: '1.25rem',
   fontWeight: 700,
   color: '#3C3E49',
   padding: '50px 0 10px'
  },
  adimUserText:{
   fontSize: '0.75rem',
   fontWeight: 500,
   color: '#64748B',
   lineHeight:'20px'
  },
  adminUserMail:{
   fontSize: '0.75rem',
   color: '#64748B',
   lineHeight:'20px'
  },
  builderIcon:{
   background: '#F0E5FF',
   borderRadius:'50%',
   maxWidth:'fit-content',
   padding:'14px'

  }
}
export default Servicespecificsettingsadmin2;

// Customizable Area End
