Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "servicespecificsettingsadmin2";
exports.labelBodyText = "servicespecificsettingsadmin2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.welcomeToAccount = "Welcome to your account, ";
exports.profile = "Profile";
exports.adminUser = "Admin User";
exports.adminEmail = "admin@example.com";
exports.editButton = "Edit";
exports.builderUser = "Builder User";
exports.accountDetails = "This account is used exclusively for onboarding and debugging purposes.";
exports.deactiveButton = "Deactivate";
exports.logout = "Log Out";
exports.apiEndPointUserDetails = "bx_block_admin_profile/admin_profile/list"
// Customizable Area End